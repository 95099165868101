import Vue from 'vue'
import App from './App.vue'
import router from '../src/router/index.js'
import  VueAMap  from 'vue-amap';
import * as echarts from 'echarts';//引入echarts


import ElementUI from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";
import '../src/main.css'
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts //引入组件
Vue.use(VueAMap);
// VueAmap.initAmap({
//   key: '496c889501c0e96ecabc93517d900e9f', // 请替换为您的 API Key
//   plugin: ['AMap.Geolocation'], // 按需加载插件
// });
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
