<template>
  <div class="avacla">
    <div class="title">
      <div class="sys1"></div>
      <div class="sys2">
        <el-button type="primary" size="mini" @click="add()">
          新增积分包<i class="el-icon-upload el-icon--right"></i>
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-delete"
          @click="del()"
          style="background-color: red"
        >
          批量删除
        </el-button>
      </div>
    </div>
    <div class="title1">
      <div class="title2">
        <el-table
          :data="Pointpacklist"
          style="width: 100%"
          height="100%"
          ref="multipleTable"
          :border="true"
          @selection-change="selDel"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            prop="name"
            label="积分包名"
            width="100"
            align="center"
          ></el-table-column>

          <el-table-column
            prop="integral"
            label="积分"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="description"
            label="描述"
            width="200"
            align="center"
          ></el-table-column>
          <el-table-column width="100" label="状态" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                :type="scope.row.status == 1 ? 'primary' : 'danger'"
                plain
                style="border: 0px solid #ffffff"
              >
                {{ scope.row.status == 1 ? "启用" : "禁用" }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="stock"
            label="库存"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column label="有效时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.beginTime }} 至 {{ scope.row.endTime }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createUser"
            label="创建者"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="updateUser"
            label="修改者"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="mod(scope.row)">
                修改
              </el-button>
              <el-button
                size="mini"
                :type="scope.row.status !== 1 ? 'success' : 'danger'"
                @click="chnageStatus(scope.row)"
              >
                {{ scope.row.status !== 1 ? "启用" : "禁用" }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      title="添加积分包"
      :visible.sync="add_dis"
      center
      :append-to-body="true"
      width="30%"
      :border="'true'"
    >
      <el-form
        style="height: 50vh; overflow: scroll"
        :model="a_object"
        :rules="addrules"
        label-position="top"
      >
        <el-form-item label="积分包名称" prop="a_name">
          <el-input v-model="a_object.a_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="积分">
          <el-input-number
            height="30px"
            v-model="a_object.a_integral"
            :min="1"
            size="small"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="积分包描述">
          <el-input
            type="textarea"
            maxlength="300"
            show-word-limit
            v-model="a_object.a_dec"
            autocomplete="off"
            size="medium"
          ></el-input>
        </el-form-item>
        <el-form-item label="积分包库存">
          <el-input-number
            height="30px"
            v-model="a_object.a_stock"
            :min="0"
            size="small"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="有效时间">
          <el-date-picker
            v-model="a_object.a_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="width: 100px" type="primary" @click="add_sure()"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="修改积分包"
      :visible.sync="mod_dis"
      center
      :append-to-body="true"
      width="30%"
      :border="'true'"
    >
      <el-form
        style="height: 50vh; overflow: scroll"
        :model="m_object"
        :rules="modrules"
        label-position="top"
      >
        <el-form-item label="积分包名称" prop="name">
          <el-input v-model="m_object.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="积分">
          <el-input-number
            height="30px"
            v-model="m_object.integral"
            :min="1"
            size="small"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="积分包描述">
          <el-input
            type="textarea"
            maxlength="300"
            show-word-limit
            v-model="m_object.description"
            autocomplete="off"
            size="medium"
          ></el-input>
        </el-form-item>
        <el-form-item label="积分包库存">
          <el-input-number
            height="30px"
            v-model="m_object.stock"
            :min="0"
            size="small"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="有效时间">
          <el-date-picker
            v-model="m_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="width: 100px" type="primary" @click="mod_sure()"
          >修 改</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="del_dis"
      width="20%"
      center
      :append-to-body="true"
    >
      <span
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        确定要删除选中项吗
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="del_sure()"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  components: {},
  data() {
    return {
      token: "",
      Pointpacklist: [],
      showmsg: "none",
      tipmsg: "",
      sh_dis: "none",
      add_dis: false,
      mod_dis: false,
      a_object: {
        a_name: "",
        a_integral: 0,
        a_dec: "",
        a_stock: 0,
        a_time: [],
      },
      addrules: {
        a_name: [
          { required: true, message: "请输入积分包名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
      },
      m_object: {},
      modrules: {
        name: [
          { required: true, message: "请输入积分包名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
      },
      m_time: [],
      delobject: [],
      ids: [],
      del_dis: false,
      checkall: false,
      checkstatus: false,
    };
  },
  computed: {},
  created() {
    this.token = localStorage.getItem("token");
    this.getPointpack();
  },
  methods: {
    getPointpack() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      axios.get("/api/admin/integral-package/selectAll", config).then((res) => {
        if (res.data.code == 1) {
          this.Pointpacklist = res.data.data;
        }
      });
    },

    add() {
      let begintime = new Date();
      // 获取当前时间的下一天
      let endtime = new Date();
      endtime.setTime(endtime.getTime() + 24 * 60 * 60);
      this.a_object.a_time = [begintime, endtime];
      this.add_dis = true;
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    add_sure() {
      let begintime = this.formatDate(this.a_object.a_time[0]);
      let endtime = this.formatDate(this.a_object.a_time[1]);
      if (this.a_object.a_name) {
        axios({
          method: "post",
          url: "/api/admin/integral-package",
          data: {
            name: this.a_object.a_name,
            integral: this.a_object.a_integral,
            description: this.a_object.a_dec,
            stock: this.a_object.a_stock,
            beginTime: begintime,
            endTime: endtime,
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token,
          },
        })
          .then((res) => {
            if (res.data.code == 1) {
              this.$message({
                type: "success",
                message: "添加成功",
              });
              this.getPointpack();
            }
          })
          .catch((err) => {
            this.$message.error("添加失败");
          });
        this.add_dis = false;
      } else {
        this.$message.error("请输入积分包名称");
      }
    },

    mod(item) {
      this.m_object = JSON.parse(JSON.stringify(item));
      console.log(this.m_object);
      let begintime = new Date(this.m_object.beginTime);
      let endtime = new Date(this.m_object.endTime);
      this.m_time = [begintime, endtime];
      this.m_object.beginTime;
      this.mod_dis = true;
    },
    mod_sure() {
      let begintime = this.formatDate(this.m_time[0]);
      let endtime = this.formatDate(this.m_time[1]);
      if (this.m_object.name == "") {
        return;
      }
      this.mod_dis = false;

      axios
        .put(
          "/api/admin/integral-package/update",
          {
            id: this.m_object.id,
            name: this.m_object.name,
            integral: this.m_object.integral,
            description: this.m_object.description,
            stock: this.m_object.stock,
            beginTime: begintime,
            endTime: endtime,
          },
          {
            headers: {
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.getPointpack();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    selDel(e) {
      this.delobject = e;
    },
    del() {
      if (this.delobject.length <= 0) {
        this.$message.error("未选择任何项");
      } else {
        this.del_dis = true;
      }
    },
    del_sure() {
      this.ids = [];
      this.delobject.forEach((item) => {
        this.ids.push(item.id);
      });
      let ids = this.ids; // 假设这是一个包含ID的数组
      let idStr = ids.join(","); // 将数组转换为逗号分隔的字符串
      axios
        .delete(`/api/admin/integral-package/deleteByIds?ids=${idStr}`, {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getPointpack();
          } else {
            this.$message.error("删除失败" + res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error("删除失败");
        });
      this.del_dis = false;
    },
    del_cel() {
      this.del_dis = false;
    },

    chnageStatus(item) {
      console.log(item);
      let newStatus = item.status ? 0 : 1;
      const url = `/api/admin/integral-package/startOrStop/${item.id}/${newStatus}`;
      console.log(url);
      axios({
        method: "put",
        url: url,
        headers: {
          "Content-Type": "application/json",
          token: this.token,
        },
      })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: newStatus ? "启用成功" : "禁用成功",
              type: "success",
            });
            item.status = newStatus;
          } else {
            this.$message.error("操作失败");
          }
        })
        .catch((err) => {
          this.$message.error("操作失败");
        });
    },
  },
  watch: {},
});
</script>

<style scoped>
.avacla {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}
.title {
  width: 100%;
  height: 10%;
}
.sys1 {
  width: 100%;
  height: 50%;
}
.sys2 {
  box-sizing: border-box;
  padding-left: 75%;
  width: 100%;
  height: 50%;
}
.title1 {
  width: 100%;
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title2 {
  width: 90%;
  height: 650px;
}
::-webkit-scrollbar {
  display: none;
}
</style>
