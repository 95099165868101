<template>
  <el-container class="IicDesignSpace">
    <el-header class="header al">
      <el-radio-group
        style="display: flex; flex-direction: row"
        v-model="twoSelone"
        @input="selArea_or_Seat"
      >
        <el-radio-button :label="true">研讨间</el-radio-button>
        <el-radio-button :label="false">座位</el-radio-button>
      </el-radio-group>
      <el-select
        v-if="twoSelone"
        v-model="campusvalue"
        placeholder="请选择校区"
        style="margin-left: 40px; width: 150px"
        @change="changeCampus1"
      >
        <el-option
          v-for="item in campusIdlist"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select
        v-if="twoSelone"
        v-model="croomvalue"
        placeholder="暂无分类"
        style="margin-left: 40px"
        @change="changeCategory1"
      >
        <el-option
          v-for="item in croomlistitem"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-if="!twoSelone"
        v-model="campusvalue"
        placeholder="请选择校区"
        style="margin-left: 40px; width: 150px"
        @change="changeCampus2"
      >
        <el-option
          v-for="item in campusIdlist"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select
        v-if="!twoSelone"
        v-model="cseatvalue"
        placeholder="暂无分类"
        style="margin-left: 40px"
        @change="changeCategory1"
      >
        <el-option
          v-for="item in cseatlistitem"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-dropdown
        trigger="hover"
        placement="bottom-start"
        style="margin-left: 40px"
      >
        <span class="el-dropdown-link">
          更多操作<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item> </el-dropdown-item>
          <el-dropdown-item>
            <el-button icon="el-icon-edit" @click="addIC()" type="text">
              添加IC空间
            </el-button>
          </el-dropdown-item>
          <el-dropdown-item>
            <el-button
              icon="el-icon-delete"
              @click="deleteIC()"
              type="text"
              style="color: red"
            >
              删除IC空间
            </el-button>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <el-main class="body flex">
      <div class="body1">
        <el-table
          @selection-change="seldel"
          :data="IClist"
          style="width: 100%"
          height="100%"
          border
        >
          <el-table-column
            type="selection"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            label="序号"
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            label="IC空间名称"
            prop="name"
            align="center"
          ></el-table-column>
          <el-table-column label="店铺图片" width="100" align="center">
            <template slot-scope="scope">
              <img
                :src="scope.row.image"
                alt=""
                style="width: 30px; height: 30px"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="IC空间容纳人数"
            prop="seatCount"
            align="center"
          ></el-table-column>
          <el-table-column
            label="IC空间评分"
            prop="envScore"
            align="center"
          ></el-table-column>
          <el-table-column
            label="IC空间收藏数"
            prop="collectorsCount"
            align="center"
          ></el-table-column>
          <el-table-column
            label="IC空间创建时间"
            prop="createTime"
            align="center"
          ></el-table-column>
          align="center"
          <el-table-column
            label="IC空间更新时间"
            prop="updateTime"
            align="center"
          >
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="modIC(scope.row)">
                修改
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
    <el-footer class="footer flex">
      <el-pagination
        layout="prev, pager, next"
        :total="totalIClist.length"
        :page-size="14"
        v-model="now_page"
        @current-change="changepage"
      ></el-pagination>
    </el-footer>
    <!-- 添加分类 -->
    <el-dialog
      title="添加分类"
      width="30%"
      :visible.sync="add_dis"
      :append-to-body="true"
      :modal="true"
      :center="true"
    >
      <div>
        <el-form
          ref="addForm"
          :model="add"
          :rules="addrules"
          label-position="top"
          label-width="100px"
          style="height: 500px; overflow-y: auto"
        >
          <el-form-item label="IC空间名称" prop="name">
            <el-input v-model="add.name"></el-input>
          </el-form-item>
          <el-form-item label="空间容纳人数" prop="number">
            <el-input type="number" v-model="add.number"></el-input>
          </el-form-item>
          <el-form-item label="选择类型" prop="type">
            <el-select
              v-model="add_type"
              placeholder="请选择"
              @change="addselectType"
            >
              <el-option
                v-for="item in typelist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择校区" prop="campus">
            <el-select
              v-model="add_campusId"
              placeholder="请选择"
              @change="addselectCmp"
            >
              <el-option
                v-for="item in campusIdlist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择IC空间分类" prop="category">
            <el-select v-model="add.categoryId" placeholder="暂无数据">
              <el-option
                v-for="item in addCategorylist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="IC空间图片:">
            <el-image
              :src="add.image"
              fit="contain"
              style="width: 250px; height: 250px"
            >
            </el-image>
            <el-upload
              class="upload-demo"
              ref="fileUpload"
              :auto-upload="false"
              :on-change="add_image"
            >
              <el-button slot="trigger" size="small" type="primary"
                >选取文件</el-button
              >
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
            </el-upload>
            <!-- 这里可以添加el-upload组件用于上传二维码，但在此省略 -->
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="flex">
        <div>
          <el-button type="primary" @click="add_sure()">确定添加</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      title="修改分类"
      width="30%"
      :visible.sync="mod_dis"
      :append-to-body="true"
      :modal="true"
      :center="true"
    >
      <div>
        <el-form
          ref="modForm"
          :model="mod"
          :rules="modrules"
          label-position="top"
          label-width="100px"
          style="height: 500px; overflow-y: auto"
        >
          <el-form-item label="IC空间名称" prop="name">
            <el-input v-model="mod.name"></el-input>
          </el-form-item>
          <el-form-item label="空间容纳人数" prop="seatCount">
            <el-input type="number" v-model="mod.seatCount"></el-input>
          </el-form-item>
          <el-form-item label="IC空间图片:">
            <el-image
              :src="mod.image"
              fit="contain"
              style="width: 250px; height: 250px"
            >
            </el-image>
            <el-upload
              class="upload-demo"
              ref="fileUpload"
              :auto-upload="false"
              :on-change="mod_image"
            >
              <el-button slot="trigger" size="small" type="primary"
                >选取文件</el-button
              >
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
            </el-upload>
            <!-- 这里可以添加el-upload组件用于上传二维码，但在此省略 -->
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="flex">
        <div>
          <el-button type="primary" @click="mod_sure()">确定修改</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="del_dis"
      width="25%"
      style="height: 300vh"
      :append-to-body="true"
    >
      <div style="display: flex; justify-content: center; font-size: 1.5rem">
        确定要删除选中项吗
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 50px;
        "
      >
        <el-button type="primary" @click="del_sure()"> 确定 </el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import axios from "axios";
import Vue from "vue";
export default Vue.extend({
  components: {},
  data() {
    return {
      toekn: "",
      now_page: 1,
      twoSelone: true,
      typelist: [
        { value: 0, label: "研讨间" },
        { value: 1, label: "座位" },
      ],
      campusvalue: 1,
      campusIdlist: [
        { value: 1, label: "大学城校区" },
        { value: 2, label: "桂花岗校区" },
        { value: 3, label: "黄埔校区" },
      ],
      IClist: [],
      totalIClist: [],
      croomlist: [],
      croomlistitem: [],
      croomvalue: 0,
      cseatlist: [],
      cseatlistitem: [],
      cseatvalue: 0,
      add_dis: false,
      add_type: 0,
      add_campusId: 1,
      addCategorylist: [],
      add: {
        id: 0,
        name: "",
        number: "",
        image: "noimage",
        seatCount: 0,
        categoryId: 0,
      },
      addrules: {
        name: [
          { required: true, message: "请输入IC空间名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        number: [
          { required: true, message: "请输入IC空间容纳人数", trigger: "blur" },
          {
            type: "number",
            min: 1,
            max: 1000,
            message: "容纳人数应在1到1000之间",
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value < 1 || value > 1000) {
                callback(new Error("容纳人数应在1到1000之间"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      mod: {
        id: 0,
        name: "",
        image: "",
        seatCount: 0,
        categoryId: 0,
      },
      modrules: {
        name: [
          { required: true, message: "请输入IC空间名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        seatCount: [
          { required: true, message: "请输入IC空间容纳人数", trigger: "blur" },
          {
            type: "number",
            min: 1,
            max: 1000,
            message: "容纳人数应在1到1000之间",
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value < 1 || value > 1000) {
                callback(new Error("容纳人数应在1到1000之间"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      mod_dis: false,
      del_dis: false,
      deletelist: [],
      deleteIdlist: [],
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getCategory();
  },
  computed: {},
  methods: {
    async getCategory() {
      let config = {
        params: {
          pageNo: 1,
          pageSize: 100,
        },
        headers: {
          token: this.token,
        },
      };
      for (const item of this.typelist) {
        const res = await axios.get(
          `/api/admin/ic-space-category/type/${item.value}`,
          config
        );
        if (res.data.code == 1) {
          item.value == 0
            ? (this.croomlist = res.data.data)
            : (this.cseatlist = res.data.data);
        }
        this.croomlistitem =
          this.croomlist.length > 0 ? this.croomlist[0].categoryList : [];
        this.croomvalue =
          this.croomlist.length > 0 ? this.croomlistitem[0].id : 0;
        this.cseatlistitem =
          this.cseatlist.length > 0 ? this.cseatlist[0].categoryList : [];
        this.cseatvalue =
          this.cseatlist.length > 0 ? this.cseatlistitem[0].id : 0;
      }
      this.getIClist(this.croomvalue);
    },
    getIClist(categoryId) {
      this.now_page = 1;
      if (categoryId != "") {
        axios
          .get(`/api/admin/ic-space/list/${categoryId}`, {
            headers: { token: this.token },
          })
          .then((res) => {
            if (res.data.code == 1) {
              console.log(res.data);
              this.totalIClist = res.data.data;
              this.IClist = this.totalIClist.slice(
                (this.now_page - 1) * 14,
                this.now_page * 14
              );
            }
          });
      } else {
        this.IClist = [];
      }
    },
    changepage(page = this.now_page) {
      this.now_page = page;
      this.IClist = this.totalIClist.slice(
        (this.now_page - 1) * 14,
        this.now_page * 14
      );
    },
    async filePut(file) {
      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`, // 边界是自动设置的，但为了某些特殊情况，您可以手动设置它
          token: this.token, // 假设 this.token 是您的认证令牌
        },
      };
      const res = await axios.post(
        "/api/admin/common/upload",
        formData,
        config
      );
      return res.data;
    },
    selArea_or_Seat(e) {
      this.twoSelone = e;
      if (this.twoSelone) {
        this.campusvalue = this.campusIdlist[0].value;
        for (let item of this.croomlist) {
          if (item.campusId == this.campusvalue) {
            this.croomlistitem = item.categoryList;
            this.croomvalue = this.croomlistitem[0].id;
            this.getIClist(this.croomvalue);
            break;
          } else {
            this.croomvalue = "";
          }
        }
      } else {
        this.campusvalue = this.campusIdlist[0].value;
        for (let item of this.cseatlist) {
          if (item.campusId == this.campusvalue) {
            this.cseatlistitem = item.categoryList;
            this.cseatvalue = this.cseatlistitem[0].id;
            this.getIClist(this.cseatvalue);
            break;
          } else {
            this.cseatvalue = "";
          }
        }
      }
    },
    changeCampus1(e) {
      this.croomlist.forEach((item) => {
        if (item.campusId == e) {
          this.croomlistitem = item.categoryList;
          this.croomvalue =
            this.croomlistitem.length > 0 ? this.croomlistitem[0].id : "";
          this.getIClist(this.croomvalue);
        }
      });
    },
    changeCampus2(e) {
      this.cseatlist.forEach((item) => {
        if (item.campusId == e) {
          this.cseatlistitem = item.categoryList;
          this.cseatvalue =
            this.cseatlistitem.length > 0 ? this.cseatlistitem[0].id : "";
          this.getIClist(this.cseatvalue);
        }
      });
    },
    changeCategory1(e) {
      this.getIClist(e);
    },
    changeCategory2(e) {
      this.getIClist(e);
    },
    addIC() {
      this.add_dis = true;
      this.croomlist.forEach((item) => {
        if (item.campusId == this.add_campusId) {
          this.addCategorylist = item.categoryList;
          this.add.categoryId =
            this.addCategorylist.length > 0 ? this.addCategorylist[0].id : "";
        }
      });
    },
    addselectType(e) {
      console.log(e);
      this.add_type = e;
      this.add_campusId = this.campusIdlist[0].value;
      this.addCategorylist =
        e == 0
          ? this.croomlist[0].categoryList
          : this.cseatlist[0].categoryList;
      this.add.categoryId =
        this.addCategorylist.length > 0 ? this.addCategorylist[0].id : "";
    },
    addselectCmp(e) {
      if (this.add_type == 0) {
        for (let item of this.croomlist) {
          if (item.campusId == e) {
            this.addCategorylist = item.categoryList;
            this.add.categoryId =
              this.addCategorylist.length > 0 ? this.addCategorylist[0].id : "";
            break;
          }
        }
      } else {
        for (let item of this.cseatlist) {
          if (item.campusId == e) {
            this.addCategorylist = item.categoryList;
            this.add.categoryId =
              this.addCategorylist.length > 0 ? this.addCategorylist[0].id : "";
            break;
          }
        }
      }
    },
    async add_image(event) {
      const file = event.raw;
      let img = await this.filePut(file);
      this.add.image = img.data;
    },
    add_sure() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          axios
            .post(
              "/api/admin/ic-space",
              {
                name: this.add.name,
                image: this.add.image,
                seatCount: this.add.number,
                categoryId: this.add.categoryId,
              },
              {
                headers: {
                  token: this.token,
                },
              }
            )
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({ message: "添加成功", type: "success" });
                this.twoSelone
                  ? this.getIClist(this.croomvalue)
                  : this.getIClist(this.cseatvalue);
                this.add_dis = false;
              } else {
                this.$message({ message: res.data.msg, type: "error" });
                this.add_dis = false;
              }
              this.add_type = 0;
              this.add_campusId = 1;
              this.add.name = "";
              this.add.image = "noimage";
              this.add.number = "";
              this.add.categoryId = "";
            });
        } else {
        }
      });
    },
    modIC(e) {
      this.mod.id = e.id;
      this.mod.name = e.name;
      this.mod.image = e.image;
      this.mod.seatCount = e.seatCount;
      this.mod.categoryId = e.categoryId;
      this.mod_dis = true;
    },
    async mod_image(event) {
      const file = event.raw;
      let img = await this.filePut(file);
      this.mod.image = img.data;
    },
    mod_sure() {
      this.$refs.modForm.validate((valid) => {
        if (valid) {
          axios
            .put(
              "/api/admin/ic-space/list",
              {
                id: this.mod.id,
                name: this.mod.name,
                image: this.mod.image,
                seatCount: this.mod.seatCount,
                categoryId: this.mod.categoryId,
              },
              {
                headers: {
                  token: this.token,
                },
              }
            )
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({ message: "修改成功", type: "success" });
                this.twoSelone
                  ? this.getIClist(this.croomvalue)
                  : this.getIClist(this.cseatvalue);
              } else {
                this.$message({
                  message: "修改失败" + res.data.msg,
                  type: "error",
                });
              }
              this.mod_dis = false;
            });
        } else {
          this.$message({ message: "请检查表单", type: "error" });
        }
      });
    },
    seldel(e) {
      this.deletelist = e;
    },
    deleteIC() {
      if (this.deletelist.length <= 0) {
        this.$message({ message: "未选中任何项", type: "error" });
      } else {
        this.del_dis = true;
      }
    },

    del_sure() {
      this.deleteIdlist = [];
      this.deletelist.forEach((item) => {
        this.deleteIdlist.push(item.id);
      });
      let ids = this.deleteIdlist; // 假设这是一个包含ID的数组
      let idStr = ids.join(",");
      console.log(idStr);
      axios
        .delete(`/api/admin/ic-space/list`, {
          headers: {
            token: this.token,
          },
          params: {
            icSpaceIds: idStr,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message.success({
              message: "删除成功",
              type: "success",
            });
            this.twoSelone
              ? this.getIClist(this.croomvalue)
              : this.getIClist(this.cseatvalue);
          } else {
            this.$message.error({
              message: "删除失败",
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.$message.error({
            message: "删除失败",
            type: "warning",
          });
        });
      this.del_dis = false;
    },
  },
  watch: {},

  mounted() {},
});
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flrow {
  display: flex;
  flex-direction: row;
}
.al {
  display: flex;
  align-items: center;
}
.js {
  display: flex;
  justify-content: center;
}
.IicDesignSpace {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  height: 8%;
  justify-content: flex-start;
  padding-left: 70px;
}
.body {
  width: 100%;
  height: 81%;
}
.body1 {
  width: 90%;
  height: 100%;
}
.footer {
  width: 100%;
  /* height: 6%; */
}
</style>
