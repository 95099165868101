<template>
  <el-container class="toolCabinet">
    <el-row class="row" :gutter="12">
      <el-col class="col" v-for="(item, index) in toolList" :key="index">
        <el-main style="font-family: ShuHei; font-size: 1.6rem">
          {{ item.title }}
        </el-main>
        <el-row class="row1" :gutter="20">
          <el-col :span="5" v-for="(item1, index1) in item.item" :key="index1">
            <el-card :body-style="cardstyle" class="al" shadow="always">
              <div class="card flrow" @click="userTool(item1.id)">
                <div class="cardIcon flex">
                  <img :src="item1.iconurl" alt="" />
                </div>
                <div class="cardTxt flex">{{ item1.name }}</div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-dialog
      title="确定要清除缓存吗"
      :visible.sync="del_dis"
      width="25%"
      :append-to-body="true"
      center
    >
      <div
        style="
          display: flex;
          justify-content: center;
          font-size: 1.5rem;
          font-family: ShuHei;
        "
      >
        该操作不可逆，请谨慎考虑
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 30px;
        "
      >
        <el-button type="primary" @click="clear()"> 确定清除 </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="获取图片CDN链接"
      :visible.sync="img_dis"
      width="50%"
      :append-to-body="true"
      :close-on-click-modal="false"
      center
    >
      <div class="js" style="width: 100%">
        <el-upload
          class="upload-demo"
          drag
          action="#"
          :on-change="getCDN"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text" style="width: 100%">
            将文件拖到此处，或<em>点击上传</em>
          </div>
          <div class="el-upload__tip" style="width: 100%" slot="tip">
            只能上传jpg/png文件, 且不超过500kb
          </div>
        </el-upload>
      </div>
      <div class="js" style="display: flex; width: 100%; margin-top: 30px">
        <el-input
          id="image_CDN_Path"
          style="
            display: flex;
            width: 90%;
            height: 100px;
            font-size: 1.1rem;
            font-weight: bold;
          "
          type="textarea"
          placeholder="此处获取图片链接"
          v-model="imageCdnPath"
        >
        </el-input>
      </div>
      <div style="width: 100%; padding-left: 5%">
        <el-button plain type="primary" size="small" @click="copyCDN()">
          一键复制链接
        </el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import Clipboard from "clipboard";
export default Vue.extend({
  components: {},
  data() {
    return {
      token: "",
      del_dis: false,
      img_dis: false,
      imageCdnPath: "",
      toolList: [
        {
          title: "常用工具",
          item: [
            {
              id: 1,
              name: "清除缓存",
              iconurl: require("../../assets/清除缓存.svg"),
            },
            {
              id: 2,
              name: "图片压缩网站",
              iconurl: require("../../assets/压缩.svg"),
            },
            {
              id: 3,
              name: "图片CDN链接",
              iconurl: require("../../assets/图片.svg"),
            },
          ],
        },
      ],
      cardstyle: {
        width: "100%",
        padding: "10px 10px",
      },
    };
  },
  created() {
    this.token = localStorage.getItem("token");
  },
  computed: {},
  methods: {
    userTool(index) {
      switch (index) {
        case 1:
          this.del_dis = true;
          break;
        case 2:
          this.gotoWebsite();
          break;
        case 3:
          this.img_dis = true;
          break;
        default:
      }
    },

    clear() {
      axios
        .post("/api/admin/common/clearHomeImageCache", null, {
          headers: {
            "Content-Type": "application/json",
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: res.data.data,
              type: "success",
            });
          } else {
            this.$message.error("清除失败");
          }
        });
    },
    gotoWebsite() {
      window.location.href = "https://www.imgdiet.com/zh-CN/jpg-compress";
    },
    async getCDN(event) {
      const file = event.raw;
      let img = await this.Fileimage(file);
      this.imageCdnPath = img.data;
    },
    async Fileimage(file) {
      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`, // 边界是自动设置的，但为了某些特殊情况，您可以手动设置它
          token: this.token, // 假设 this.token 是您的认证令牌
        },
      };
      const res = await axios.post(
        "/api/admin/common/upload",
        formData,
        config
      );
      return res.data;
    },
    async copyCDN() {
      try {
        await navigator.clipboard.writeText(this.imageCdnPath);
        this.$message({
          type: "success",
          message: "复制成功",
        });
      } catch (err) {
        try {
          let element = document.querySelector("#image_CDN_Path");
          element.select();
          document.execCommand("copy");
          this.$message({
            type: "success",
            message: "复制成功",
          });
        } catch (err) {
          this.$message({
            type: "error",
            message: "复制失败，请手动复制！",
          });
        }
      }
    },
  },
  watch: {},
});
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.al {
  display: flex;
  align-items: center;
}
.js {
  display: flex;
  justify-content: center;
}
.flrow {
  display: flex;
  flex-direction: row;
}
.toolCabinet {
  width: 100%;
  height: 100%;
}
.row {
  width: 100%;
  height: 100%;
}
.col {
  width: 100%;
}
.row1 .el-col {
  margin-top: 20px;
}
.row1 .el-col .el-card {
  height: 70px;
  border-radius: 5px;
}
.row1 .el-col .el-card:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease;
}
.card {
  width: 100%;
  height: 100%;
}
.cardIcon {
  width: 30%;
}
.cardTxt {
  flex: 1;
  font-family: "ShuHei";
  font-size: 1.1rem;
  /* background-color: aquamarine; */
}
.cardIcon img {
  width: 3rem;
  height: 3rem;
}
.upload-demo {
}
</style>
